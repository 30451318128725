
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        









































































































































































/* stylelint-disable declaration-no-important */
.tv-svod,
[class*='tv-svod--'] {
  @extend %voo-tv-plus-legacy;

  position: relative;

  ::v-deep {
    .price-box__price {
      display: none;
    }
    .voo-tv-plus__slider {
      margin-top: 5rem;
      padding: 2rem 0;

      @include mq(m) {
        padding: 2rem;

        &::before {
          top: -1rem;
          bottom: -1rem;
        }
      }
    }
    .voo-tv-plus__slider__slogan::before {
      display: none;
    }

    .hero__slides__item__icon .icon {
      max-width: 12rem;
    }

    .generic-slider__slides__item {
      width: 33%;
      padding-right: 2rem;
      transition: opacity 0.3s;

      &[class*='is-viewed'] {
        opacity: 0;
        pointer-events: none;
      }
    }

    .generic-slider {
      margin-left: $spacing * 1.5;
    }

    .generic-slider__slides__item {
      transition: none;

      img {
        border-radius: 5px;
      }
    }
  }
}

// TO DO - CLEAN STYLE
.voo-tv-plus__slider {
  display: none;
}
// END - CLEAN STYLE

.tv-svod__devices {
  color: $white;
  background-color: $c-blue-dark;
}

.compatible-devices__label {
  margin-bottom: $spacing * 1.5;
  font-size: 1.8rem;
}

.compatible-devices__logos {
  display: flex;
}

.compatible-devices__logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: $spacing * 2.25;

  .icon {
    height: 3rem;
    margin-bottom: $spacing / 2;
    fill: $white;
    transition: fill 0.2s $ease-out-quart;
  }

  .text {
    color: $white;
    font-size: 1.1rem;
    font-weight: 900;
    transition: color 0.2s $ease-out-quart;
  }

  &:hover {
    .icon {
      fill: $c-pink-medium;
    }

    .text {
      color: $c-pink-medium;
    }
  }
}

// EXPORT B2C
.voo-tv-plus__slider {
  .flickity-viewport {
    overflow: visible;
  }

  .generic-slider__slides__item {
    width: 33%;
    padding-right: 2rem;
    transition: opacity 0.3s;

    &[class*='is-viewed'] {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.voo-tv-plus__slider {
  position: relative;
  width: calc(100% + 2rem);
  margin-top: 5rem;

  &::before {
    content: '';
    position: absolute;
    top: -1rem;
    right: 0;
    left: 0;
    bottom: -1rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: lighten($gray-lightest, 4);
  }

  @include mq(m) {
    width: calc(100% + 7rem);
    margin-top: 20rem;

    &::before {
      top: -5rem;
      bottom: -4rem;
    }
  }

  @include mq(l) {
    width: 50vw;
  }

  @include mq(xl) {
    display: flex;

    .generic-slider {
      margin-left: 0;
    }
  }
}

.voo-tv-plus__slider__slogan {
  @extend %fw-medium;

  position: relative;
  z-index: 1;
  padding: 0 $spacing * 1.5 $spacing;
  font-size: 1.5rem;
  white-space: nowrap;

  svg {
    fill: $c-pink-medium;
  }
  span {
    display: block;
  }

  @include mq(m) {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -13.8rem;
      left: 0;
      width: 11.8rem;
      height: 8.8rem;
      background: url('/assets/images/tvplus/avatar-1-rail.png') no-repeat;
      background-size: cover;
    }
  }

  @include mq(l) {
    font-size: 2.4rem;
  }

  @include mq(xxxl) {
    padding: $spacing $spacing * 5 $spacing $spacing * 1.5;
  }
}

.voo-tv-plus__movies {
  ::v-deep {
    .speedlines {
      width: 100%;
    }

    .section-row__content {
      margin-left: 0;
    }

    .section-row__picture {
      max-height: 70rem;
    }

    @include mq($until: l) {
      .section-row__picture {
        display: none;
      }
    }
  }
}
